


export const navList = [
    {
        navImg:require("@/views/Friend/Img/book.png"),
        content:"添加通讯录好友",
        rightHide:true,
        path:"/addressBook"
    },
    {
        navImg:require("@/views/Friend/Img/place.png"),
        content:"查看本校校园",
        rightHide:true,
        path:"/schoolCampus"
    }
]
