import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store"
import FindFriendStoreType from './indexType'
import {BodyType} from "@/Api/Money/indexType";

@Module({
    name:"FindFriend",
    store,
    dynamic: true,
})
export default class FindFriend extends VuexModule implements FindFriendStoreType{
    FindFriendUpData: BodyType = {
        pageNo: 1, pageSize: 15
    };
    get getFindFriendUpData(){
        return this.FindFriendUpData
    }
    @Mutation
    SetFindFriendUpData(data: BodyType): void {
        this.FindFriendUpData = data
    }

    FindFriendList: any[] = [];
    get getFindFriendList(){
        return this.FindFriendList
    }
    @Mutation
    SetFindFriendList(data: any[]): void {
        this.FindFriendList = data
    }

}

export const FindFriendStore = getModule( FindFriend )
